.testimonials {

	padding-top: 250px;

	h3 {
		color: $color-extra;
		font-size: rem(20px);
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 3vw;
		margin-top: 100px;
	}

	&__item {
		box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.15);
		border-radius: 12px;
		transition: all .4s;
		background-color: white;

		p {
			margin-top: 10px;
			font-size: rem(14px);
		}

		&:hover {
			box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
		}
	}

	.testimonials__item {
		padding: 1.5vw 1vw;
		background-image: url('../../images/bracket.svg');
		background-repeat: no-repeat;
		background-position: 93% 10%;
	}

	@include mq($until: xxl) {
		&__item {
			p {
				font-size: rem(12px);
			}
		}

		&__list {
			column-gap: 2.6vw;
		}
	}

	@include mq($until: xl) {

		&__item {
			p {
				font-size: rem(12px);
			}
		}

		&__list {
			margin-top: 75px;
			column-gap: 2vw;
		}
	}

	@include mq($until: lg) {

		padding-top: 150px;

		&__item {
			p {
				font-size: rem(14px);
			}
		}

		&__list {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 3vw;
			row-gap: 1vw;
		}

		.testimonials__item {
			padding: 3vw 2vw;
		}
	}

	@include mq($until: md) {
		padding-top: 120px;
	}

	@include mq($until: xs) {
		&__list {
			grid-template-columns: repeat(1, 1fr);
			row-gap: 2vw;
			margin-top: 50px;
		}

		.testimonials__item {
			padding: 5vw 3vw;
		}
	}
}