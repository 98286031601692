.home {
    &__top {
        height: 60vh;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 500px;
    }

    &__see-all {
        margin-top: 40px;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(4px);
        padding: .5vw 2.5vw;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 5px;

        a {
            color: white;
            text-decoration: underline;
            font-weight: 600;
            transition: all .3s;

            &:hover {
                color: $color-extra;
            }
        }
    }

    h1 {
        text-align: center;
        color: white;
        font-weight: 600;
        letter-spacing: -1.35px;
        line-height: 1.5;
    }

    .apartments {
        background-image: url('../../images/bg1.svg');
        background-repeat: round;
        background-size: contain;
        position: relative;
        &::before {
            content: '';
            background: transparent radial-gradient(closest-side at 50% 50%, #ECF3FA 0%, #EAF0F5 62%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            z-index: -1;
        }
    }

    .news {        
        background-image: url('../../images/bg1.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        &::before {
            content: '';
            background: transparent radial-gradient(closest-side at 50% 50%, #FBF7F1 0%, #FCFBF4 62%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
            width: 50%;
            height: 50%;
            position: absolute;
            left: 0;
            z-index: -1;
        }
    }

    .testimonials {
        background-image: url('../../images/bg1.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    @include mq($until: md) {
        &__top {
            height: 88vh;
        }
    }

    @include mq($until: sm) {
        h1 {
            font-size: 6vw;
        }
    }

    @include mq($until: sm) {
        &__see-all {
            a {
                font-size: rem(17px);
            }
        }
    }
}

.pre-footer {

    &__see-all {
        margin-top: 40px;
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(4px);
        padding: .5vw 2.5vw;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 5px;

        a{
            color: white;
            text-decoration: underline;
            font-weight: 600;
            transition: all .3s;
            &:hover {
                color: $color-main;
            }
        }
    }

    margin-top: 150px;
    height: 35vw;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 7vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    h2 {
        color: white;
        text-shadow: 0 0 10px #3e3e3e;
    }

    @include wawes-on-top;

    @include mq($until: lg) {
        padding-top: 150px;
        height: auto;
    }

    @include mq($until: sm) {
        h2 {
            padding: 0 20px;
            text-align: center;
        }
    }

    @include mq($until: xs) {
        padding-top: 120px;
        padding-bottom: 50px;
    }
}