.search-bar {
    background-color: rgba(0, 0, 0, 0.23);
    border-radius: 7px;
    width: fit-content;
    padding: 25px;

    .input-daterange {
        display: grid;
        column-gap: 3.5%;
        grid-template-columns: 48% 48%;
    }

    &__icons {
        position: relative;

        &:after {
            content: "";
            width: 18px;
            height: 41%;
            display: block;
            background-size: cover;
            top: 27%;
            right: 11px;
            position: absolute;
            pointer-events: none;
        }

        &.cal {
            &:after {          
                background-image: url(../../images/search/cal.svg);
            }  
        }

        &.pers {
            &:after {          
                background-image: url(../../images/search/pers.svg);
            }  
        }
    }

    &__form {
        display: grid;
        column-gap: 1.35%;
        grid-template-columns: 31% 40% 20% 5%;

        input, select {
            width: 100%;
            outline: none;
            border: 0;
            padding: 12px 40px 12px 15px;
            border-radius: 5px;
            line-height: 1;
            height: 100%;
            background-color: white;
        }

        select {
            padding-top: 13px;
            -webkit-appearance: none; 
            appearance: none; 
            &:-ms-expand {
                display: none;
            }
        }

        button {
            width: 100%;
            padding: 0;
            background: none;
            border: none;
            background-color: $color-main;
            height: 100%;
            border-radius: 5px;
            transition: all .3s;
            &:hover {
                background-color: $color-extra;
                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.226);
            }
        }

        svg {
            width: 100%;
        }
    }

    @include mq($until: md) {
        &__form {
            grid-template-columns: 400px;
            row-gap: 10px;

            button {
                padding: 7px;
            }
        }

        .input-daterange {
            display: grid;
            column-gap: 3%;
            grid-template-columns: 48.5% 48.5%;
        }
    
    }

    @include mq($until: xs) {
        &__form {
            grid-template-columns: 87vw;
        }

        padding: 20px;
    }
}