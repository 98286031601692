.news-card{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &__header{
        margin: 0 5%;
        color: $color-extra;
        width: 60%;
    }

    &__heading {
        display: flex;
        margin-bottom: 3.2vw;
    }

    &__more {
        margin-top: 3.2vw;
        display: block;
        font-weight: 600;
        color: $color-extra;
        text-decoration: underline;
    }

    &:nth-of-type(1) {
        flex-direction: row-reverse;

        &> div {
            padding-left: 0;
        }
    }

    &> div {
        width: 50%;
        height: 33vw;
        padding: 4%;
    }

    img {
        width: 50%;
        height: 100%;
        object-fit: cover;
        border-radius: 21px;
        transition: all .4s;
    }

    &:hover {
        text-decoration: none;

        img {
            box-shadow: 0 0 9px 5px rgba(0, 0, 0, 0.15);
        }
    }

    @include mq($until: xl) {
        &> div {
            padding: 2.7%;
        }

        &__heading {
            margin-bottom: 2.3vw;
        }

        &__more {
            margin-top: 2.3vw
        }

        &__header {
            width: 80%;
        }
    }

    @include mq($until: lg) {

        &> div {
            width: 100%;
            height: auto;
            margin-top: 15px;
        }

        &:nth-of-type(1) {
            flex-direction: column;

            &> div {
                padding: 2.7%;
            }
        }

        flex-direction: column;

        img {
            width: 100%;
            height: 40vw;
        }
        
        &__header {
            width: 40%;
        }
    }

    @include mq($until: md) {
        &__header {
            width: 60%;
        }

        img {
            height: 50vw;
        }

        &> div {
            svg {
                width: 50px;
                height: 50px;
            }
        }
    }

    @include mq($until: xs) {

        &__header {
            width: 90%;
            margin: 0 0 0 15px;
            font-size: rem(20px);
        }

        &> div {
            svg {
                width: 30px;
                height: 30px;
            }
        }
        img {
            height: 60vw;
        }
    }
}
