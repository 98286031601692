.news{

    &__categories{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        a, span{
            &::after{
                content: '|';
                margin-left: 10px;
                margin-right: 10px;
            }
            &:last-of-type::after{
                display: none;
            }
        }
    }

    &__image{
        margin-bottom: 30px;
    }

    .gallery{
        margin-top: 60px;
        margin-bottom: 30px;
    }

    &__top {
        height: 50vh;
        display: flex;
        position: relative;
        align-items: flex-end;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include wawes-on-bottom;

        h1 {
            color: white;
            margin-bottom: 9vh;
            width: 50%;
            font-size: rem(60px);
            text-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        }
    }

    &__content {
        padding-top: 8vh;
    }

    .row {
        img {
            border-radius: 20px;
        }
    }

    @include mq($until: xxl) {
        &__top {
    
            h1 {
                font-size: rem(45px);
            }
        }
    }

    @include mq($until: xl) {
        h1 {
            width: 55%;
        }
    }

    @include mq($until: lg) {
        h1 {
            width: 60%;
        }
    }

    @include mq($until: md) {
        
        &__top {
    
            h1 {
                font-size: rem(35px);
                width: 100%;
            }
        }
    }

    @include mq($until: md) {

    }
}