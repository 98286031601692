.management {
    margin-top: 150px;
    height: 55vw;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include wawes-on-top;

    &__slider {
        position: absolute;
        top: 0;
        width: 100%;
        height: 90%;
        z-index: -1;

        &-item {
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            background-position: bottom;
        }

        div {
            height: 100%;
        }
    }

    &__article {
        text-align: center;
        max-width: 900px;
        margin: 5vw auto;
        z-index: 3;
    }

    h2 {
        color: white;
        text-align: center;
        margin-bottom: 5vw;
    }

    &__linear-bg {
        z-index: 2;
        width: 100%;
        height: 30%;
        bottom: 10%;
        position: absolute;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 1.59%, rgba(255, 255, 255, 0.42) 17.36%, rgba(255, 255, 255, 0.8) 34.23%, rgba(255, 255, 255, 0.96) 52.18%, #FFFFFF 79.1%, #FFFFFF 106.04%);
    }   

    @include mq($until: lg) {
        margin-top: 125px;
        height: 65vw;
    }

    @include mq($until: md) {
        height: auto;
        padding-top: 150px;

        &__article {
            padding: 5vw;
        }

        &__linear-bg {
            height: 50%;
        }
    }

}