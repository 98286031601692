.apartment {

    &__slide {
        height: 100%;

        img {
            width: 99vw;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__slider {
        height: 70vh;
        @include wawes-on-bottom;
        &:before {
            z-index: 1;
        }
    }

    &__photo {
        height: 100%;
    }

    .slick-slide {
        &> div{
            height: 100%;
        }
    }

    .slick-track {
        height: 70vh;
    }

    &__container {
        display: flex;
        padding-top: 10vh;
        justify-content: space-between;

        &_left {
            width: 40%;

            li {
                color: $color-extra;
                font-weight: 600;
                margin-top: 0;
                margin-bottom: 10px;
            }

            p {
                width: 90%;
            }

            .buttons-group, .register {
                display: none;
            }
        }

        &_right {
            width: 55%;
        }
    }

    form {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button {
            width: 55%;
        }

        .button-secondary {
            width: 33%;
        }
    }

    .slick-arrow {
        display: block;
        position: absolute;
        top: 75%;
        background-color: transparent;
        z-index: 2;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        backdrop-filter: blur(10px);
        color: rgb(223, 223, 223);
        border-color: rgb(223, 223, 223);
        border-style: solid;
        font-size: rem(25px);
        transition: all .4s;
        &:hover {
            color: white;
            border-color: white;
            color: $color-main;
        }

        &.slick-disabled {
            &:hover {
                color: rgb(223, 223, 223);
                border-color: rgb(223, 223, 223);
            }
        }

        &.slick-prev {
            left: initial;
            right: 14%;
            i {
                padding-right: 5px;
            }
        }
    
        &.slick-next {
            right: 10%;
            i {
                padding-left: 5px;
            }
        }
    
    }

    &__header {
        color: $color-extra;
    }

    &__description {
        margin-top: 125px;
        margin-bottom: 45px;
    }

    &__amenities {
        &> div {
            display: flex;
            margin-bottom: 65px;

            ul {
                width: 50%;
            }
        }
    }

    .map-wrapper {
        height: 25vw;
    }

    h3 {
        font-size: rem(18px);
        color: $color-main;
        margin-bottom: 25px;
    }

    &__surrounding {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    &__icons {
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        background-color: white;

        ul {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            padding: 2.5vw;
            gap: .5vw;
            row-gap: 1.5vw;
        }

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            margin: 10px 0;
            span {
                margin-top: 15px;
                font-size: .58vw;
            }
        }

        img {
            width: 40%;
        }
    }

    .map, &__icons {
        margin-top: 50px;
    }

    @include mq($until: xl) {

        .slick-arrow {

            &.slick-prev {
                right: 15%;
            }
        
            &.slick-next {
                right: 8%;
            }
        }

        &__container {
    
            &_left {
                width: 48%;
            }
    
            &_right {
                width: 50%;
            }
        }

        .map, &__icons {
            margin-top: 30px;
        }

        &__description {
            margin-top: 90px;
            margin-bottom: 30px;
        }

        &__icons ul {
            grid-template-columns: repeat(5, 1fr);

            span {
                font-size: .68vw;
            }
        }
    }

    @include mq($until: lg) {
        
        .slick-arrow {
            top: 85%;
            &.slick-prev {
                right: 20%;
            }
        
            &.slick-next {
                right: 0;
                margin-right: $padding-lg;
            }
        }

        &__description {
            margin-top: 60px;
        }

        &__amenities > div {
            margin-bottom: 30px;
        }

        form .button-secondary {
            width: 38%;
        }

        
        &__icons ul {
            grid-template-columns: repeat(4, 1fr);

            span {
                font-size: 1vw;
            }
        }
    }

    @include mq($until: md) {

        .map-wrapper {
            margin-top: 50px;
            height: 50vw;
        }

        &__icons ul {
            grid-template-columns: repeat(6, 1fr);

            span {
                font-size: 1.2vw;
            }
        }

        &__container {
            flex-direction: column;
            &_left {
                width: 100%;

                li {
                    margin-bottom: 6px;
                }

                p {
                    width: 100%;
                }

                .buttons-group, .register {
                    display: initial;
                }
            }

            &_right {
                width: 100%;

                .buttons-group, .register {
                    display: none;
                }
            }
        }
    }

    @include mq($until: sm) {
        
        .slick-arrow {
        
            &.slick-next {
                right: 0;
                margin-right: $padding-sm;
            }
        }

        &__icons ul {
            grid-template-columns: repeat(5, 1fr);

            span {
                font-size: 1.4vw;
            }
        }
    }

    @include mq($until: xs) {
        
        .slick-arrow {
            top: 55%;

            &.slick-prev {
                left: 0%;
                margin-left: $padding-xs;
            }
        
            &.slick-next {
                right: 0;
                margin-right: $padding-xs;
            }
        }

        &__icons ul {
            grid-template-columns: repeat(4, 1fr);
            column-gap: 3vw;
            row-gap: 5vw;
            span {
                font-size: 1.8vw;
            }
        }
    }

    @media (max-height: 770px) {
        &__slider, .slick-track {
            height: 55vh;
        }
    }

    @media (max-width: 420px) {
        .buttons-group {
            form {
                flex-direction: column;
            }

            .button, .button-secondary {
                width: 100%;
                margin-bottom: 12px;
            }
        }

        &__icons ul {
            grid-template-columns: repeat(3, 1fr);
            column-gap: 3vw;
            row-gap: 6vw;
            span {
                font-size: 2.5vw;
            }
        }
    }
}
