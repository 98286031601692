// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}


@mixin wawes-on-top{
    &:before {
        content: '';
        background-image: url('../../images/img-bottom-mask.svg');
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 15%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
    }
}

@mixin wawes-on-bottom{
    &:before {
        content: '';
        background-image: url('../../images/img-top-mask.svg');
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 15%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
    }
}
