.footer {

  &__nav {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    border-bottom: 1px solid rgb(221, 221, 221);
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    border-bottom: 1px solid rgb(221, 221, 221);

    p {
      margin-bottom: 0;
    }

    svg {
      width: 20px;
      fill: gray;
      margin-left: 30px;
    }
  }

  h5 {
    color: $color-main;
    font-size: rem(17px);
    margin-bottom: 25px;
  }

  &__right {
    &> div {
      align-items: center;
      width: 900px;
      justify-content: space-between;
    }

    a {
      &:hover {
        text-decoration: none;
        color: $color-main;
        svg {
          fill: $color-main;
        }
      }
    }
  }

  &__tel, &__mail {

    svg {
      margin-left: 0;
      margin-right: 10px;
      fill: $color-main;
      margin-bottom: 3px;
    }

    a {
      font-style: italic;
      font-weight: 500;
      color: #6F6F6F;
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 900px;
    justify-content: space-between;
  }

  &__menuItem {

  }

  &__menuLink {
    color: $color-extra;
    font-weight: 500;

    &:hover {
      color: $color-main;
      text-decoration: none;
    }
  }

  &-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
    &__copyright {
      position: absolute;
      left: calc(50% - 80px);
    }
    div, a {
      color: $paragraph-color;
      font-weight: 600;
      font-size: rem(13px);
    }

    @include mq($until: md) {
      &__copyright {
        position: absolute;
        left: calc(50% - 65px);
      }
    }

    @include mq($until: xs) {
      &__realization{ 
        img {
          width: 20vw;
        }
      }

      &__copyright {
        position: absolute;
        left: calc(50% - 15vw);
      }

      div, a {
        font-size: 3vw;
      }
    }
  }

  @include mq($until: xl) {
    
    &__right {
      &> div {
        width: 750px;
      }
    }
    
    &__menuList {
      width: 750px;
    }
  }

  @include mq($until: lg) {

    &__right {
      &> div {
        width: 65vw;
      }
    }
    
    &__menuList {
      width: 650px;
    }

    &__logo {
      margin-right: 50px;
    }

    &__nav {
      padding: 20px 0 10px 0;
    }
  }

  @include mq($until: md) {
    &__contact {
      flex-direction: column;
    }
    
    &__right {
      margin-top: 30px;

      &> div {
        width: 100%;
      }
    }

    h5 {
      margin-bottom: 10px;
    }

  }

  @include mq($until: sm) {
    
    &__menuList {
      width: 100%;
    }

    &__nav {
      flex-direction: column;
      padding: 20px 0 10px 0;
    }
  }

  @include mq($until: xs) {

    &__menuList {
      display: none;
    }

    &__nav {
      padding: 40px 0 40px 0;
    }

    &__right {
      &> div {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &__social {
      svg {
        margin-left: 0;
        margin-right: 20px;
      }
    }

  }

}

