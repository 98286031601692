.apartments {

    padding-top: 200px;
    padding-bottom: 200px;

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 2.5vw;
        column-gap: 2vw;
    }

    &__heading {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-bottom: 60px;

        h2 {
            margin-bottom: 0;
        }

        a {
            margin-left: 20px;
            font-weight: 600;
            text-decoration: underline;
            font-size: rem(16px);
        }
    }

    &__description {
        max-width: 1200px;
        margin: 150px auto 50px;
    }

    @include mq($until: lg) {
        padding-top: 100px;
        padding-bottom: 150px;

        &__list {
            grid-template-columns: repeat(2, 1fr);            
        }
    }
    
    @include mq($until: sm) {
        &__heading {

            a {
                font-size: rem(17px)
            }
        }

        &__description {
            margin: 70px auto 0;
        }
    }
    
    @include mq($until: xs) {

        &__list {
            grid-template-columns: repeat(1, 1fr);            
        }

        &__heading {
            margin-bottom: 40px;
            flex-direction: column;
            align-items: center;
            a {
                margin: 10px 0;
            }
        }
    }
}

.apartment-card {
    display: block;
    border-radius: 20px;
    overflow: auto;
    transition: all .4s;
    box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    height: 100%;

    img {
        width: 100%;
        height: 16vw;
        border-radius: 20px;
        object-fit: cover;
    }

    &__name, b {
        font-size: rem(18px);
    }

    &__name, &__more {
        color: $color-main;
    }

    &__price {
        font-size: rem(13px);
        margin-top: 5px;
    }

    &__row {
        display: flex;
        justify-content: space-between;

        b {
            color: $color-extra;
        }

        &_excerpt {
            flex: auto;
            border-top: 1px solid rgb(219, 219, 219);
            border-bottom: 1px solid rgb(219, 219, 219);
            margin: 15px 0;
            padding: 15px 0;

            &:before {
                background-image: url('../../images/house.png');
            }
        }

        &_city {

            &:before {
                background-image: url('../../images/marker.png');
            }
        }

        &_city, &_excerpt {
            padding-left: 35px;
            position: relative;

            &:before {
                content: '';
                left: 0;
                width: 22px;
                height: 24px;
                display: block;
                position: absolute;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &__info {
        padding: 1.5vw;
        min-height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &:hover {
        text-decoration: none;
        box-shadow: 0 0 9px 5px rgba(0, 0, 0, 0.15);
    }

    @include mq($until: xl) {

        img {
            height: 21vw;
        }

    }

    @include mq($until: lg) {

        img {
            height: 30vw;
        }

        &__info {
            padding: 2vw;
        }

        &__row {
            &_excerpt {
                margin: 10px 0;
                padding: 10px 0;
            }

            &_city, &_excerpt {
                padding-left: 25px;

                &:before {
                    width: 16px;
                    height: 18px;
                }
            }
        }

        &__info {
            min-height: 220px;
        }

    }

    @include mq($until: sm) {
        border-radius: 15px;

        img {
            border-radius: 15px;
        }

        &__info {
            padding: 3vw;
        }
    }

    @include mq($until: xs) {
        img {
            height: 55vw;
        }

        &__info {
            padding: 5vw;
        }

        &__row {
            &_excerpt {
                margin: 10px 0;
                padding: 10px 0;
            }

            &_city, &_excerpt {
                padding-left: 25px;

                &:before {
                    width: 16px;
                    height: 18px;
                }
            }
        }

        &__info {
            min-height: 200px;
        }
    }
}