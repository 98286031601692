.subsite {

    background: transparent radial-gradient(closest-side at 35% 69%, #FBF7F1 0%, #FCFBF4 72%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    
    .apartments {
        padding-top: 100px;
        padding-bottom: 0;
    }

    &__top {

        &_bg {
            height: 50vh;
            display: flex;
            position: relative;
            align-items: flex-end;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
    
            @include wawes-on-bottom;
    
            h1 {
                color: white;
                margin-bottom: 9vh;
                width: 50%;
                font-size: rem(60px);
                text-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
            }
        }
    }

    &__content {
        padding-top: 8vh;
    }

    .row img {
        border-radius: 20px;
    }

    .map {
        border-radius: 12px;
    }

    @include mq($until: xxl) {
        &__top {
    
            h1 {
                font-size: rem(45px);
            }
        }
    }

    @include mq($until: xl) {
        h1 {
            width: 55%;
        }
    }

    @include mq($until: lg) {
        h1 {
            width: 60%;
        }
    }

    @include mq($until: md) {
        
        &__top {
    
            h1 {
                font-size: rem(35px);
                width: 100%;
            }
        }
    }

    @include mq($until: md) {

    }

    &+ .pre-footer {
        padding-bottom: 12%;

        h2 {
            font-size: rem(30px);
        }

        @include mq($until: md) {
            h2 {
                font-size: rem(25px);
            }    
        }
    }
}
