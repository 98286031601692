.news-list{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    &--promo{
        margin-bottom: 30px;
    }
    &__header-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__header{
        margin-bottom: 30px;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }
    &__link{
        color: $color-main;
    }

    @include mq($until: lg) {
        grid-gap: 40px;  
    }
}