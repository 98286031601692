.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 110px;
  background-color: white;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-extra;
    font-weight: 500;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }

  @include mq($until: md) {
    &__menuLink {
      font-size: 3vw;
    }
  }

  @include mq($until: sm) {
    min-height: 80px;
    &__menuLink {
      font-size: 4vw;
    }
  }

  @include mq($until: xs) {
    &__menuLink {
      font-size: 5vw;
    }
  }

  @media (max-width: 992px) and (orientation:landscape) {
    min-height: 50px;

    &__logo {
      width: 90px;
    }

    &__menuLink {
      font-size: 3vw;
    }

    &__menuList > .header__menuItem {
      margin: 0 0 7px 0;
    }

    &__nav {
      padding-top: 7px;
      padding-bottom: 7px;
      &--fullscreen {
        height: calc(100vh - 80px);
        padding-top: 80px;
      }
    }
  }
}