.button {
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  border-radius: 5px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: solid 1px $color-main;
  align-self: flex-start;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: $color-extra;
    border-color: $color-extra;
  }

  &-secondary{
    background-color: $color-extra;
    border-color: $color-extra;
    font-weight: 300;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }

  span {
    line-height: 0;
    color: white;

    strong {
      font-size: rem(17px);
    }
  }
}