.icons-set {
  display: grid;
  text-align: center;
  gap: 1vw;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    max-width: 200px;
    justify-self: center;

    img {
      width: 130px;
    }

    &::before {
      content: none;
    }

  }
  
  &__item-text {
      font-weight: 600;
      font-size: rem(16px);
  }

  @include mq($until: md) {
    &__item {
      img {
        width: 60px;
      }
    }
    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(13px);
    }
  }

  &.mngmnt {
    gap: 2vw;
    text-align: inherit;
    z-index: 3;

    .icons-set__item-text {
      font-size: .8vw;
      transition: all .3s;
    }

    .icons-set__item {
      flex-direction: row;
      background-color: white;
      width: 100%;
      height: auto;
      max-width: inherit;
      padding: 1.2vw 1.8vw;
      border-radius: .9vw;
      // cursor: pointer;
      transition: all .3s;

      &:hover {
        background-color: $color-extra;

        .icons-set__item-text {
          color: white;
        }
      }

      img {
        width: 4.3vw;
        margin-right: 1.6vw;
      }
    }
  }

  @include mq($until: lg) {
    &.mngmnt {
      .icons-set__item-text {
        font-size: .95vw;
      }
    }
  }

  @include mq($until: md) {
    &.mngmnt {
      .icons-set__item-text {
        font-size: 1.5vw;
      }
    }
  }
  
  @include mq($until: xs) {
    &.mngmnt {
      .icons-set__item {
        padding: 1.6vw 2.2vw;
        &-text {
        font-size: 2vw;
        }
        img {
          width: 7vw;
          margin-right: 3vw;
        }
      }
    }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
