.popular {
    margin: 40px 0;

    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    &__item {
        height: 12.5vw;
        margin-top: 0;
    }

    &__link {
        width: 100%;
        height: 100%;
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        align-items: flex-end;
        transition: .3s;

        &:hover {
            box-shadow: 0 0 0 4px #00000025;
            background-size: 105%;
        }

        &.last {
            align-items: center;
            justify-content: center;

            span {
                background-color: rgba(0, 0, 0, 0.781);
                border-top-right-radius: 0;
            }
        }

        span {
            color: white;
            font-weight: 600;
            font-size: .76vw;
            padding: .4vw .73vw;
            background-color: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(5px);
            border-top-right-radius: 15px;
        }
    }

    &__article {
        text-align: center;
        max-width: 1050px;
        margin: 0 auto;
        margin-top: 100px;

        a {
            margin-top: 40px;
            text-decoration: underline;
            display: block;
            font-weight: 600;
        }
    }

    @include mq($until: lg) {
        &__article {
            margin: 0 $padding-lg;
        }

        &__list {
            grid-template-columns: repeat(3, 1fr);
        }

        &__item {
            height: 24vw;
        }

        &__link {
            span {
                font-size: 1.5vw;
                padding: 0.7vw 1.8vw;
            }
        }
    }

    @include mq($until: sm) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }

        &__item {
            height: 29vw;
        }
    }

    @include mq($until: xs) {
        &__article {
            margin: 0 $padding-xs;
        }

        &__item {
            height: 42vw;
        }

        &__link {
            span {
                font-size: 2.5vw;
                padding: 1.7vw 2.8vw;
            }
        }

        &__article {
            a {
                font-size: rem(17px);
            }
        }
    }
}